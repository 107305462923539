import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Box from '@material-ui/core/Box';
import BreakLine from '../atoms/breakLine'

const Notice = (props) => {
  const { title, notice } = props

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Divider />
      <Box mb="0.5rem" />
      {notice.map((notice) => (
        <React.Fragment key={notice.title}>
          <Typography variant="h5" gutterBottom>
            {notice.title}
          </Typography>
          <Typography variant="subtitle2" gutterBottom paragraph>
            {notice.date.substring(0, 10)}
          </Typography>
          <Typography component="span">
            <BreakLine value={notice.content.content} />
          </Typography>
          <Box mb="1rem" />
        </React.Fragment>
      ))}
    </>
  );
}

export default Notice;

Notice.propTypes = {
  notice: PropTypes.array,
  title: PropTypes.string,
};