import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Img from 'gatsby-image';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import BreakLine from '../atoms/breakLine'

const useStyles = makeStyles((theme) => ({
  section: {
    height: '600px',
    padding: theme.spacing(2),
  },
}));

const Article = ({ article }) => {
  const classes = useStyles();

  return (
    <Grid key={article.order} item xs={12} md={6}>
      <Paper variant="outlined" elevation={3} className={classes.section}>
        <Img alt="" fluid={article.image.fluid} />
        <Box mb="0.5rem" />
        <Typography variant="h6" gutterBottom>
          {article.title}
        </Typography>
        <Box mb="0.5rem" />
        <Typography >
          <BreakLine value={article.content.content} />
        </Typography>
      </Paper>
    </Grid>
  );
}

export default Article;

Article.propTypes = {
  article: PropTypes.object,
};