import React from "react"
import { useStaticQuery, graphql } from 'gatsby';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Notice from '../components/organisms/notice'
import Article from '../components/molecules/article'
import Payment from '../components/molecules/payment'

const query = graphql`
  query {
    allContentfulNotice(sort: {fields: date, , order: DESC}) {
      edges {
        node {
          id
          title
          date
          content {
            content
          }
        }
      }
    }
    allContentfulArticle(sort: {fields: order, , order: ASC}) {
      edges {
        node {
          title
          content {
            content
          }
          image {
            fluid(resizingBehavior: SCALE) {
              ...GatsbyContentfulFluid_tracedSVG
            }
          }
          order
        }
      }
    }
  }
`

const Index = () => {
  const contentful = useStaticQuery(query)

  return <>
    <Notice title="お知らせ" notice={contentful.allContentfulNotice.edges.map(({ node }) => node)} />
    <Box mb="1.5rem" />
    <Grid container spacing={3}>
      {contentful.allContentfulArticle.edges.map(({ node }) =>
        <Article key={node.order} article={node} />
      )}
    </Grid>
    <Box mb="1rem" />
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Payment />
      </Grid>
    </Grid>
    <Box mb="1rem" />
  </>;
}

export default Index;